<template>
  <div class="linksinbio content">

    <div class="inner lores s12">
      <p class="title lores s21">
        &gt; Bio Links
      </p>
      <a class="linkinbio flipped secondary" v-for="link in linkinbio" :key="link.text" :href="link.url">{{link.text}}</a>
      <a class="social flipped tertiary" v-for="(url, social) in socials" :key="social" :href="url" rel="me">{{social}}</a>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Bio',
  props: {
    socials: Object,
    linkinbio: Object
  }
}
</script>

<style lang="scss" scoped>
.linksinbio {
  position: relative;
}

.linksinbio .inner {
  display: inline-block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 4rem);
  max-width: 480px;
}

a.linkinbio, a.social {
  text-align: center;
  display: block;
  padding: 0 1rem;
  margin-bottom: 1rem;
}

a.linkinbio {
  padding: 1rem;
}
</style>
